<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="addnewinfo" :rules="addnewinfo_rules" ref="addnewinfo" label-width="110px"
						label-position="left" class="demo-ruleForm" style="padding-left: 20px;">
						<el-form-item label="菜单名称" prop="title">
							<el-input v-model="addnewinfo.title" style="width: 60%;" placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="菜单图标">
							<el-input v-model="addnewinfo.icon" style="width: 20%;" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item label="所属父级">
							<el-cascader
								 style="width: 40%;"
								 v-model="addnewinfo.pid_rules"
							    :options="listdata"
							    :props="{ 
									checkStrictly: true,
									label:'title',
									value:'id',
									children:'chlidren'
								}"
							    clearable>
							</el-cascader>
							<!-- <el-select clearable filterable placeholder="请选择" style="width: 40%;">
								<el-option v-for="item in listdata" :key="item.id">
								</el-option>
							</el-select> -->
						</el-form-item>
						<el-form-item label="菜单类型" required style="display: flex;align-items: center;">
							<el-radio-group v-model="addnewinfo.menutype">
								<el-radio :label="1">目录</el-radio>
								<el-radio :label="2">菜单</el-radio>
								<el-radio :label="3">按钮</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="前端路由">
							<el-input v-model="addnewinfo.url" style="width: 60%;" placeholder="请输入"></el-input>
						</el-form-item>
						
						<el-form-item label="排序">
							<el-input v-model="addnewinfo.weigh" type="number" style="width: 10%;"
								placeholder="1"></el-input>
						</el-form-item>
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="setaddnew">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {				
				addnewinfo: {
					title:'',
					icon:'',
					pid:'',
					pid_rules:'',
					menutype:2,
					url:'',
					weigh:1,
				},
				addnewinfo_rules: {
					title: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				listdata:[]
			}
		},
		created() {
			this.id = this.$route.query.id
			this.getfatherlist()
			this.getdetail()
		},
		methods: {
			getdetail(){
				this.$post({
					url:'/api/role/details',
					params:{
						id:this.id			
					}
				}).then((res)=>{
					this.addnewinfo = res
				})
			},
			getfatherlist(){
				this.$post({
					url:'/api/role/f_index',
					params:{										
					}
				}).then((res)=>{
					let selectdata = res.list
					this.listdata = selectdata	
				})
			},
			submitForm() {
				this.addnewinfo.pid = this.addnewinfo.pid_rules?this.addnewinfo.pid_rules[this.addnewinfo.pid_rules.length-1]:0
				this.addnewinfo.pid_rules = this.addnewinfo.pid_rules?this.addnewinfo.pid_rules.join(','):''
				this.$refs.addnewinfo.validate((valid) => {
					if (valid) {
						this.$post({
							url:'/api/role/edit',
							params:this.addnewinfo
						}).then((res)=>{
							this.$message.success('修改成功')
							this.$router.push('/system/menu_list')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			
			setaddnew() {
				this.$router.push('/system/menu_list')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>